<template>
  <Body>
    <slot />
  </Body>
</template>

<style>
body {
  background: transparent;
}
</style>
